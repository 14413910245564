.section-element{
    margin-top: 100px;
    background-color: #222;
    margin-left: 15px;
    margin-right: 15px;
    border-radius:3%;
    padding-top:10px;
    padding-bottom:10px;
}
.picture{
    width: 100px;
    height: 100px;
}
.s-title{
    font-size: 1.5rem;
    color: whitesmoke;
    font-weight: bold;
    text-align: center
}
.div-container{
   margin-bottom: 15px;
   background-color: red;
   border-radius: 3%;
   background-color:#222;
   color: whitesmoke;
   padding-top: 10px;
   padding-bottom: 10px;
   font-size: 13px;
}
.section-service{
    margin-top: 100px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius:3%;
    padding-top:10px;
    padding-bottom:10px;
}
.sect{
margin-top:100px;
}
.p-text{
    color: whitesmoke;
}
.status{
    float: left;
}
.glb {
    width: 100px;
    height: 100px;
}