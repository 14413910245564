
.div-pic{
    padding-top: 10px;
    width:100px;
    height:100px;
}
.pic{
    max-width: 100%;
    min-height: 100%;
    object-fit: cover;
}
.title{
    text-align: left;
    padding-bottom: 20px;
 
}
.vid-proj{
  margin: auto;
  width: 100%;
}
.down{
  padding-top: 100px;
}
.number{
    color: yellow;
    font-weight: bold;
    font-size: 15px;
    padding-left: 3%;
}
.vid-resp{
    width: 100%;
    height: 235px;
}
.container1 {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }
  
  .container2 {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    height: 100vh;
  }
  
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  .doc-viewer {
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
    height: 1100px;
    border: none;
  }
  .div-content_prj{
    background: var(--clr-black);
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 1%;
    }
@media screen and (min-width: 992px) {
    .vid-resp{
        height: 600px;
    }
    .vid-proj{
      width: 75%;
    }
}
