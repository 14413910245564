/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.padding-left {
  padding-left: 10px;
  font-size: 15px;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.red{
  background-color:red;
}
.blue{
  background-color:blue;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: #181818;
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}
.nume{
  display: block;
  font-size: 18px;
  float:left;
  font-weight: bold;
  margin-top: 13px;
  margin-left: -15px;
}
.button-nav{
  float:right;
  margin-top: 10px;
}
.columns{
  columns: 2 auto;
}
.section-content{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: #181818;
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}
.div-content{
background: var(--clr-black);
margin-top: 20px;
padding-top: 10px;
padding-bottom: 10px;
border-radius: 1%;
}
.red{
  background-color:red;
  max-width: 768px;
}
model-viewer{
  width: 100%;
  height: 100%;
}
.img{
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.img1{
  width: 75%;
  object-fit: cover;
  object-position: center;
}
.div-top{
  min-height: 100vh;
  padding-top: 15%;
  background-color: var(--clr-black);
  border-radius: 3%;
}
.menu-toggle {
  display: flex;
  justify-content: center;
}

.metin-section{
  margin: 10px;
  padding:10px;
}
.profile-picture{
  padding-top: 10px;
  width:200px;
}
.profile-pic{
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
  border: gray;
  border-radius: 5%;
  padding-bottom: 10px;
}
ul {
  list-style-type: none;
}
a {
  color: white;
}
h1,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  padding-top: 3%;
  font-size: 2.5rem;
  text-align: center;
  color: #fff;
}
.padding-top{
  padding-top: 100px;

}
.button {
  font-size: 2.5rem;
  color: whitesmoke;
  float: right;
  margin-top: -10px;
}
button{
  float:right;
  margin-top: 10px;
}
h3 {
  font-size: 2rem;
  color:white;
}
.center{
  text-align:center;
}
h4 {
  font-size: 0.875rem;
}
p {
  color:whitesmoke;
  text-align: center;
  padding: 2%;
  font-size: 15px;
}
.font18{
  font-size:15px;
}



@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }

}
/*  global classes */

/* section */
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}
clean.hover{
  text-decoration: none;
}
@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
  .block{
    display: block;
  }
  .cont1{
    columns: 2 auto;
  }
  .itemlist{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  }
  .test{
    padding-top: 30%;
  }
  h2{
    padding-bottom:10px;
  }
}

/*
=============== 
Navbar
===============
*/
nav {
  background: var(--clr-black);
  box-shadow: var(--light-shadow);
}
.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
.nav-toggle {
  font-size: 1.5rem;
  color: var(--clr-primary-5);
  background: transparent;
  border-color: transparent;
  transition: var(--transition);
  cursor: pointer;
}
.nav-toggle:hover {
  color: var(--clr-primary-1);
  transform: rotate(90deg);
}
.logo {
  height: 40px;
}
.links a {
  color: whitesmoke;
  font-size: 1.2rem;
  text-transform: capitalize;
  letter-spacing: var(--spacing);
  display: block;
  padding-top: 5px;
  padding-bottom:5px;
  padding-left: 1%;
  transition: var(--transition);
  text-decoration: none;
  font-weight: bold;
}
hr{
  border-color: whitesmoke;
}
.links a:hover {
  background: whitesmoke;
  color: black;
  padding-left: 1.5rem;
}
.social-icons {
  display: none;
}
.links-container {
  height: 0;
  overflow: hidden;
  transition: var(--transition);
}
.show-container {
  height: 10rem;
}
@media screen and (min-width: 768px) {
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }
  .nav-header {
    padding: 0;
  }
  .nav-toggle {
    display: none;
  }

  
  .links-container {
    height: auto !important;
    float: right;
    padding-top: 20px;
  }
  .links {
    display: flex;
  }
  .links a {
    padding: 0;
    margin: 0 0.5rem;
  }
  .links a:hover {
    padding: 0;
    background: transparent;
  }
  .social-icons {
    display: flex;
  }
  .social-icons a {
    margin: 0 0.5rem;
    color: var(--clr-primary-5);
    transition: var(--transition);
  }
  .social-icons a:hover {
    color: var(--clr-primary-7);
  }
  .nume{
    margin-top: 20px;
    margin-left: -15px;
  }
  .buttons {
    font-size: 15px;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    border: 2px solid #424244;
    color:whitesmoke;
    background:#424244;
    text-decoration: none; 
    font-weight: bold;

  }
  .buttons:hover{
    background: #424244;
    color: whitesmoke;
    border: 2px solid #c7c7d1;
    cursor: pointer;
  }
}

@media screen and (min-width: 1200px) {
  .menu-toggle{
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .buttons-toggle{
    display: none;
  }
  .menu-toggle{
    padding-bottom: 10px;
  }
  .dropdown-menu{
    width: 100%;
    background-color: transparent;

  }
  .buttons3{
    font-size: 15px;
    border-radius: 5px;
    border: 2px solid #424244;
    color:whitesmoke;
    background:#424244;
    text-decoration: none; 
    font-weight: bold;
    width: 150px;
    float: right;
  }
  .buttons3:hover{
    background: #424244;
    color: whitesmoke;
    border: 2px solid #c7c7d1;
    cursor: pointer;
  }
  .orizontal-center {
    margin: 10px;
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
}
@media screen and (max-width: 1024px)
{
  .itemlist{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
} 
@media screen and (max-width: 768px)
{
  .itemlist{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
} 