.h-img{
 height:100px;
}
.top{
    margin-top: 20%;
}

.block{
    display: inline-block;
}
@media screen and (min-width: 768px){
    .align-middle{
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}
@media screen and (max-width: 768px){
    .col1{
        order:2;
    }
    .col2{
        order:1;
    }
  }
.home-font{
    font-size: 15px;
}
.header-font{
    font-size: 20px;
}